<template>
  <v-list-item three-line>
    <v-list-item-content v-if="lineItem !== null">
      <v-list-item-title>{{ lineItem.quantity }} x {{ lineItem.label }}</v-list-item-title>
      <v-list-item-subtitle>{{ lineItem.created | moment('DD/MM/YYYY HH:mm') }}</v-list-item-subtitle>
      <v-list-item-subtitle><span class="text--primary">{{ lineItem.unitPrice.amount }} {{ lineItem.unitPrice.currency_code }} {{ $t('perUnit') }}</span><span v-html="lineItem.dynamicDescription" /></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="lineItem !== null">
      <v-list-item-action-text>{{ lineItem.totalPrice.amount }} {{ lineItem.totalPrice.currency_code }}</v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
    name: 'LineItemListItem',
    props: {
      lineItem: {
        type: Object, 
        default: () => {}
      }
    }
}
</script>